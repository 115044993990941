import type { RouteRecordRaw } from '#vue-router'
import {
  useIsSubdomain,
  useVerifyAndSetSubdomain,
} from '~/composables/useSubdomain'

export const subdomainRoutes = (_routes: Readonly<RouteRecordRaw[]>) => {
  const isSubdomain = useIsSubdomain()

  useVerifyAndSetSubdomain()

  if (isSubdomain.value) {
    const userRoute = _routes.filter((i) => {
      return i.path.includes('/sub-domain') || i.meta?.allowOnPartnerPage
    })

    const userRouteMapped = userRoute.map((i) => ({
      ...i,
      path: i.path.replace(/\/sub-domain[/]?/, '/'),
      ...{ meta: { ...i.meta, layout: 'partner' } },
    }))

    return userRouteMapped
  }

  return _routes
}
