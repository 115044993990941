import { getGtmContentGroup } from '~/utils/getGtmContentGroup'
import { useIsSubdomain } from '~/composables/useSubdomain'

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return

  const {
    public: { baseUrl },
  } = useRuntimeConfig()
  const gtm = useKftGtm()
  const excludedRoutes = ['/embedded/', '/ping/', '/widgets/']
  const { isEmbeddedCheckout } = useBookingSolution()
  const isSubdomain = useIsSubdomain()

  const shouldEnableGtm =
    gtm &&
    gtm.enable &&
    !isEmbeddedCheckout.value &&
    !excludedRoutes.some((x) => to.path.match(x)) &&
    !isSubdomain.value

  if (!shouldEnableGtm) return

  gtm.enable()
  if (to.path === from.path) return

  setTimeout(() => {
    gtm.pushToDataLayer({
      routeName: to.name,
      content_group: getGtmContentGroup(to),
      pageType: 'PageView',
      pageUrl: baseUrl + to.fullPath,
      pagePath: to.fullPath,
      pageTitle: document?.title || '',
      event: 'nuxtRoute',
    })
  }, 2000)
})
