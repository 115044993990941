import type { KftApiDefaultResponse } from '~/composables/types/kft-api'
import type { Supplier } from '~/composables/types/event'

export const useSubdomain = () =>
  useState<string | undefined>('subdomain', () => undefined)

export const useIsSubdomain = () => {
  const subdomain = useSubdomain()
  return computed(() => Boolean(subdomain.value))
}

const nonBookingSolutionDomains = [
  'www',
  'localhost',
  'localhost:3000',
  'front-hml',
  'gokonfetti',
]

const isVercelPrWithNoSubdomain = (host: string) => {
  const splittedHost = host.split('.')
  return (
    splittedHost.length === 3 &&
    splittedHost[1] === 'vercel' &&
    splittedHost[2] === 'app'
  )
}

const isBookingSolution = (host?: string) => {
  const splittedHost = host?.split('.') || []
  const subdomain = splittedHost?.[0] || ''
  const hostIsIpAddress = host?.match(
    /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(:\d{1,5})?$/
  )

  return (
    host &&
    !hostIsIpAddress &&
    splittedHost.length > 1 &&
    !nonBookingSolutionDomains.some((x) => subdomain.match(x)) &&
    !isVercelPrWithNoSubdomain(host)
  )
}

export const useVerifyAndSetSubdomain = () => {
  const { ssrContext } = useNuxtApp()
  const subdomainState = useSubdomain()

  // check for subdomain in the url on the server
  if (process.server && ssrContext && ssrContext.event.node.req) {
    const req = ssrContext.event.node.req
    const host = req.headers.host
    const subdomain = host?.split('.')[0]

    if (isBookingSolution(host)) {
      subdomainState.value = subdomain
    } else {
      subdomainState.value = undefined
    }
  }

  // check for subdomain in the url on the client
  if (process.client && window.location.hostname) {
    const host = window.location.hostname
    const subdomain = host.split('.')[0]

    if (isBookingSolution(host)) {
      subdomainState.value = subdomain
    } else {
      subdomainState.value = undefined
    }
  }
}

export const useSubdomainData = async () => {
  const subdom = useSubdomain()
  const fullLocalizedUrl = useFullLocalizedUrl()

  const {
    data: fetchedData,
    error,
    refresh: loadSubdomainData,
  } = await useKftApiFetch<KftApiDefaultResponse<Supplier>, Supplier>(
    `v1/store/suppliers/${subdom.value}/subdomain?include=logo,booking_solution_settings`,
    {
      key: 'subdomainData:' + subdom.value,
      transform: (data) => data?.data,
    }
  )

  if (error.value || !fetchedData.value.id) {
    navigateTo(
      { path: fullLocalizedUrl('/') },
      { external: true, redirectCode: 301 }
    )
  }
  return {
    subdomainData: fetchedData,
    loadSubdomainData,
  }
}
