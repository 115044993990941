import revive_payload_client_9JCdRRZqoiqdAa_e5XZNJmaEvoflEhcEYGs9x_Dfbjc from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_rA6AMz09hTB7XkhlP2557Fud11bliEXmh3yan3D4h_Q from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ry17YKraKX_UrSCApX8jkqtMOrFYuK54CT_YBEszTiY from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_z15UngK8VOBEBl7gk5qHCYWUqA95J97B_frPVmko48o from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_rubWJQY_79MzdGyYcTmEUoJnI3POkV1lX_FDyxZr7uc from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jLdNSuxg7utEOG0i4jGL1RVNluQzU2seV0OzHQlotr8 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Ivhzl83axX_kGct45pLP3YgdlGJ6vFdquZxaFWX0wrc from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_ZabQ4fdD_9QCMT8nitnEXEGhPNyN9TOoOQfrH9SXb3A from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_5vyvdNSPlp7IUQQvolRLCEJU1_qrv7PKD5PTG1d083I from "/app/node_modules/.pnpm/@nuxtjs+algolia@1.11.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.8.2___magicast@0.3_wa6hjl2mi4ieyi3yuzfwshum7i/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_gPCmkz3umYyW1JD0v0UQ9ZOj1XxP45XxRfdycywAkuQ from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node_w3pwnyjrf7xcumoz4ipnh7guya/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_h3sMO3pCJF9n9EIdc8AQyY2T1EytUrmBKSOgaMRmKuM from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_xjoQ6t0P0r3zxB6uc4dp4z_D7TdFmOxLpH6ccQaxtds from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_bAHhN1i2Xh6A3hH1exG4OEdiQw4YDGcGiSPZfkelmvQ from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/app/plugins/sentry.client.ts";
import ssg_detect_pmj6hy1VNz4j8Uq92nQCNI1CO_74f_BsYmZ4s0rPT6s from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_9JCdRRZqoiqdAa_e5XZNJmaEvoflEhcEYGs9x_Dfbjc,
  unhead_rA6AMz09hTB7XkhlP2557Fud11bliEXmh3yan3D4h_Q,
  router_ry17YKraKX_UrSCApX8jkqtMOrFYuK54CT_YBEszTiY,
  payload_client_z15UngK8VOBEBl7gk5qHCYWUqA95J97B_frPVmko48o,
  navigation_repaint_client_rubWJQY_79MzdGyYcTmEUoJnI3POkV1lX_FDyxZr7uc,
  check_outdated_build_client_jLdNSuxg7utEOG0i4jGL1RVNluQzU2seV0OzHQlotr8,
  chunk_reload_client_Ivhzl83axX_kGct45pLP3YgdlGJ6vFdquZxaFWX0wrc,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_ZabQ4fdD_9QCMT8nitnEXEGhPNyN9TOoOQfrH9SXb3A,
  plugin_5vyvdNSPlp7IUQQvolRLCEJU1_qrv7PKD5PTG1d083I,
  plugin_gPCmkz3umYyW1JD0v0UQ9ZOj1XxP45XxRfdycywAkuQ,
  switch_locale_path_ssr_h3sMO3pCJF9n9EIdc8AQyY2T1EytUrmBKSOgaMRmKuM,
  route_locale_detect_xjoQ6t0P0r3zxB6uc4dp4z_D7TdFmOxLpH6ccQaxtds,
  i18n_bAHhN1i2Xh6A3hH1exG4OEdiQw4YDGcGiSPZfkelmvQ,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  ssg_detect_pmj6hy1VNz4j8Uq92nQCNI1CO_74f_BsYmZ4s0rPT6s
]