import { formatPriceAmount } from '~/utils/currencyHelpers'
import type { Coupon } from '~/composables/types/checkout'
import type { Price } from '~/composables/types/event'

interface CouponValidateResponse {
  coupon: Coupon
  discount: null | any
}

const getDiscountAmount = (coupon: Coupon, locale: string) => {
  if (coupon.discount_type === 'FIXED_VALUE') {
    return formatPriceAmount(Number((coupon.value as Price).amount), locale)
  } else if (coupon.discount_type === 'PERCENTAGE_VALUE') {
    return coupon.value + '%'
  }
  return undefined
}

export default defineNuxtRouteMiddleware(async (to) => {
  if (!process.client) return
  const { $i18n } = useNuxtApp()

  const sessionVoucherCookie = useCookie('sessionVoucher')
  const { addNotification } = useNotifications()
  const { t, locale } = $i18n

  const query = to?.query
  const sessionVoucher = query?.c

  if (sessionVoucherCookie.value === sessionVoucher) return

  if (sessionVoucher) {
    try {
      const couponValidateResponse =
        await kftApiRequest<CouponValidateResponse>(
          `v1/store/coupon/${sessionVoucher}/validate`
        )

      const coupon = couponValidateResponse?.data?.coupon

      if (!coupon || !coupon.value) {
        throw new Error("Coupon doesn't exist or has no value")
      }

      const discountAmount = getDiscountAmount(coupon, locale.value)

      const successMessage =
        coupon.success_message ||
        t('checkout.sessionVoucher.successMessage', {
          amount: discountAmount,
        })

      if (discountAmount) {
        sessionVoucherCookie.value = sessionVoucher.toString()
        await addNotification({
          type: 'SUCCESS',
          message: successMessage,
          showIcon: true,
        })
      }
    } catch (e) {
      const message = e?.data?.message || t('cart.sessionVoucher.errorMessage')
      await addNotification({
        type: 'ERROR',
        message,
        showIcon: true,
      })
    }
  }
})
