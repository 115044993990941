export type KftNotificationType = 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR'

export interface KftNotification {
  title?: string
  message: string
  type: KftNotificationType
  showIcon?: boolean
  htmlContent?: string
}

export const useNotifications = () => {
  const { $scrollToTop } = UseKftContext()

  const notifications = useClientSideState<KftNotification[]>(
    'useNotifications-notifications',
    () => []
  )

  const removeNotification = (id) => {
    if (id > -1) {
      notifications.value.splice(id, 1)
    }
  }

  const addNotification = async (
    notification: KftNotification,
    { shouldScrollToTop }: { shouldScrollToTop?: boolean } = {
      shouldScrollToTop: true,
    }
  ) => {
    const newIdx = notifications.value.length
    notifications.value.push(notification)
    if (shouldScrollToTop && $scrollToTop) {
      await $scrollToTop()
    }

    await nextTick()

    return () => removeNotification(newIdx)
  }

  const handleCloseNotification = () => {
    // should remove the first item of the array
    notifications.value.shift()
  }

  return {
    notifications,
    addNotification,
    handleCloseNotification,
  }
}
