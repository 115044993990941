<template>
  <div
    v-if="isOpen"
    class="flex justify-between rounded-md p-4"
    :class="typeClasses"
  >
    <div class="flex items-center w-full">
      <component
        :is="icons[type] || 'div'"
        v-if="!hideIcon"
        :height="iconSize || 30"
        :width="iconSize || 30"
        :class="iconClasses"
      />
      <div class="w-full">
        <div v-if="title" class="ml-3 mb-2 font-medium text-sm leading-4">
          {{ title }}
        </div>
        <slot name="content">
          <div v-if="content" class="ml-3 font-normal text-sm leading-4">
            {{ content }}
          </div>
        </slot>
      </div>
      <div v-if="closeCard" class="-mt-4 -mr-2 cursor-pointer">
        <span class="rotate-45 block text-lg" @click="handleClose">+</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { IconExclamationTriangleFill } from '#components'
import IconExclamationOctagonFill from '~/components/icons/IconExclamationOctagonFill.vue'
import InfoCircleFill from '~/public/icons/info-circle-fill.svg?component'

const props = withDefaults(
  defineProps<{
    title: string
    content: string
    closeCard: boolean
    hideIcon: boolean
    iconSize: string
    type: 'info' | 'success' | 'error' | 'warning'
  }>(),
  {
    title: '',
    content: '',
    hideIcon: false,
    closeCard: false,
    type: 'warning',
  }
)

const typeClasses = {
  'bg-teal-50 text-teal-600': props.type === 'info',
  'bg-danger-light text-gray-700': props.type === 'error',
  'bg-success-light text-gray-700': props.type === 'success',
  'bg-warning-light text-gray-700 ': props.type === 'warning',
}

const icons = {
  info: InfoCircleFill,
  error: IconExclamationTriangleFill,
  success: 'check-circle',
  warning: IconExclamationOctagonFill,
}

const iconClasses = {
  'text-success': props.type === 'info',
  'bg-danger-light text-danger': props.type === 'error',
  'bg-lime-100 text-gray-700': props.type === 'success',
  'bg-warning-light text-yellow-500': props.type === 'warning',
}

const isOpen = ref(true)
const handleClose = () => {
  isOpen.value = false
}
</script>

<style lang="postcss" scoped>
&__close-button {
  margin-top: -15px;
  span {
    cursor: pointer;
    font-size: 1.2rem;
  }
}
</style>
