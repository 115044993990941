import validate from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45redirect_45to_45legacy_45app_45global from "/app/middleware/1.redirect-to-legacy-app.global.ts";
import _2_45redirect_45from_45json_45global from "/app/middleware/2.redirect-from-json.global.ts";
import _3_45gtm_45global from "/app/middleware/3.gtm.global.ts";
import handle_45session_45voucher_45global from "/app/middleware/handle-session-voucher.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45redirect_45to_45legacy_45app_45global,
  _2_45redirect_45from_45json_45global,
  _3_45gtm_45global,
  handle_45session_45voucher_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "event-old-permalinks": () => import("/app/middleware/event-old-permalinks.ts")
}