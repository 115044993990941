import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__cgg7fbc76pa7ovy5aabpvefaiq/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}