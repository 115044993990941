
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as faqpXvNV3IVqZ7r7EZ6X3TxlTw6jDWpvHRhXzG2SNVEy_4Meta } from "/app/pages/faq.vue?macro=true";
import { default as kits4qhLQlStlk1UBub17mOLQMiEGq41ubqoAikGJGZAMrIMeta } from "/app/pages/kits.vue?macro=true";
import { default as pingXjxpkFVF8GQKAuq37SrKbZhHDlq6solZ7AGLI0HOv_45gMeta } from "/app/pages/ping.vue?macro=true";
import { default as searchWiCwPORWRkcZm1zH29UWKNMF4Vb5hM_q5Mi2yI40hlcMeta } from "/app/pages/search.vue?macro=true";
import { default as _91slug_93jk8NboFQK8o6mOnMfbHsC5QZC2vwrXPlCkqMu7REe_45oMeta } from "/app/pages/t/[slug].vue?macro=true";
import { default as impressum3zzQy_454eQIfFpJ0LzE7oAVVZr5GATwT7mh6WVc9V9esMeta } from "/app/pages/impressum.vue?macro=true";
import { default as _91slug_93avbsmegKRJZMYUWMMAb6svjmxYB8KXoYxy9t5w_45JTscMeta } from "/app/pages/b2b/[slug].vue?macro=true";
import { default as _91slug_93iBZZIx5uruMAMOi9JdheoEhEz2PW7bM1k_rNmmoolPwMeta } from "/app/pages/b2c/[slug].vue?macro=true";
import { default as indexbrnW4vQDDmMzvzWXMEkjsVUJcgVaCaU74BYgRj_DLgQMeta } from "/app/pages/[city]/index.vue?macro=true";
import { default as _91category_93bwwf1jAiRo8reV4FhnKcFuoHATkSID9O8dbQYiTdjL8Meta } from "/app/pages/c/[category].vue?macro=true";
import { default as indexFvrGDTPKrce3FFQT7ij_hMGsmc6y5aKKjhJE2Sa_45GNEMeta } from "/app/pages/partner/index.vue?macro=true";
import { default as _91slug_93uGCKKC0ZMyweieY8ZQ4_45DM9XghGZx7NazNK8zFFgOekMeta } from "/app/pages/review/[slug].vue?macro=true";
import { default as indexGsFsGsKI_utlx2Iox0NU96g0tiOjy2h96PZ2pzsYl9IMeta } from "/app/pages/giftcard/index.vue?macro=true";
import { default as indexrsMovMnPAjYSiLcmtYlTJY_45ATOK7nA1wg0PnFCgJgFQMeta } from "/app/pages/magazine/index.vue?macro=true";
import { default as privacy_45policyzgG_45S68_451GHrVnCshLKLsmg43yjC4fB7Q3UU1pkWXTMMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as terms_45suppliert904fGvJfOcyGqBCg6wWBppfFHw0LROvWS8pKAacWM0Meta } from "/app/pages/terms-supplier.vue?macro=true";
import { default as indexyEZvi6gHI_45gMzPLm50ILF9eB9dY_45Vqn_drlaTaFEDQYMeta } from "/app/pages/teamevent/index.vue?macro=true";
import { default as _91boxPermalink_93lWf3QYZiCCLpdSuyZqumnhl2JfVwZQdNlEp_45_UM5H28Meta } from "/app/pages/k/[boxPermalink].vue?macro=true";
import { default as indexXaeQOVwCnOyEhz9QjAfentyQuSjBcw4oqW3AChwd1mwMeta } from "/app/pages/sub-domain/index.vue?macro=true";
import { default as _91city_93_0DWg42VypyTTIBW4aBVqJAXNYUXhY8pPZhp1rnQycEMeta } from "/app/pages/teamevent/[city].vue?macro=true";
import { default as _91category_93EZCnJ8QHWEYzVMlsLSmQk4zOWt_S3YxORxogGhZSfuYMeta } from "/app/pages/[city]/[category].vue?macro=true";
import { default as employee_45giftsfzNpPPtj2UACvulouceKKOrA1LFHUcBPCxazjpO_BsMMeta } from "/app/pages/b2b/employee-gifts.vue?macro=true";
import { default as _91eventPermalink_93m4cL0oikOFf6co1kVSXvMEEncUnVF1a7Ye7UC0OQnKMMeta } from "/app/pages/e/[eventPermalink].vue?macro=true";
import { default as _91special_93USc0GTc_45cup07TTwbBx7tkHqhP4zmSwia_45yfraj9JJAMeta } from "/app/pages/giftcard/[special].vue?macro=true";
import { default as giftcard5ovBVsS296fsBW4HMaEe8RJudPjxIEuddiqvQehBVV8Meta } from "/app/pages/sub-domain/giftcard.vue?macro=true";
import { default as all_45widgetsO68DqLGvHQqLopCQJCWaEKxin9H6zu52YYaFqAxotCgMeta } from "/app/pages/widgets/all-widgets.vue?macro=true";
import { default as _91partnerPermalink_93hUyjZCY_5tvnqURrYnzov7GBIkhcXvlrS1o34UFvEIcMeta } from "/app/pages/p/[partnerPermalink].vue?macro=true";
import { default as terms_45and_45conditionsRU14D3TdeoevONy87DoGrbDHcLlYVbN3gK81tRUROX4Meta } from "/app/pages/terms-and-conditions.vue?macro=true";
import { default as _91articleSlug_93BtQBnLypwY6_2xuuRu1iV1fcU3Dj3pEoaAoPXjOr_MAMeta } from "/app/pages/magazine/[articleSlug].vue?macro=true";
import { default as mitarbeitergeschenkenKvoTYDJSCuL4qZXkrEweZX8N9rKkfur6rhD9V0i7b0Meta } from "/app/pages/b2b/mitarbeitergeschenke.vue?macro=true";
import { default as _91partnerId_939kNYrouLZUEvftk_45Fe6GijV3Dp_45vbLGeerVeD_45cCX9EMeta } from "/app/pages/widgets/badge/[partnerId].vue?macro=true";
import { default as _91previewPermalink_93FmdUEzCh55z_hyPfKrd_45_45idpMxpTV_45zFgzal6nMtTgIMeta } from "/app/pages/preview/[previewPermalink].vue?macro=true";
import { default as _91partnerId_93X_45NK6yaKWUWkvJEsrQj92nGbW9dm5n6lWB4koy30jKYMeta } from "/app/pages/widgets/general-banner/[partnerId].vue?macro=true";
import { default as _91partnerId_93shN6i9v6ZU2r6M6EpFHzS5U_FBvZrW0lZfaHabKsPpIMeta } from "/app/pages/widgets/partner-banner/[partnerId].vue?macro=true";
import { default as _91eventId_93_7tvjEQ2i7WAUbxKSkUx5zG3z_h44K5fpumJ1wwJ_4gMeta } from "/app/pages/widgets/reviews-by-event-id/[eventId].vue?macro=true";
import { default as _91partnerId_93AK5WWBy6IAXNJ6rS6rU6cVNLO6jkeF1HVCrJHNKcbGEMeta } from "/app/pages/widgets/reviews-by-partner-id/[partnerId].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "faq___en-us",
    path: "/en-us/faq/",
    meta: faqpXvNV3IVqZ7r7EZ6X3TxlTw6jDWpvHRhXzG2SNVEy_4Meta || {},
    component: () => import("/app/pages/faq.vue")
  },
  {
    name: "faq___de-de",
    path: "/de-de/faq/",
    meta: faqpXvNV3IVqZ7r7EZ6X3TxlTw6jDWpvHRhXzG2SNVEy_4Meta || {},
    component: () => import("/app/pages/faq.vue")
  },
  {
    name: "kits___en-us",
    path: "/en-us/kits/",
    meta: kits4qhLQlStlk1UBub17mOLQMiEGq41ubqoAikGJGZAMrIMeta || {},
    component: () => import("/app/pages/kits.vue")
  },
  {
    name: "kits___de-de",
    path: "/de-de/kits/",
    meta: kits4qhLQlStlk1UBub17mOLQMiEGq41ubqoAikGJGZAMrIMeta || {},
    component: () => import("/app/pages/kits.vue")
  },
  {
    name: "ping___en-us",
    path: "/en-us/ping/",
    component: () => import("/app/pages/ping.vue")
  },
  {
    name: "ping___de-de",
    path: "/de-de/ping/",
    component: () => import("/app/pages/ping.vue")
  },
  {
    name: "index___en-us",
    path: "/en-us/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de-de",
    path: "/de-de/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "search___en-us",
    path: "/en-us/search/",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___de-de",
    path: "/de-de/search/",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "t-slug___en-us",
    path: "/en-us/t/:slug()/",
    meta: _91slug_93jk8NboFQK8o6mOnMfbHsC5QZC2vwrXPlCkqMu7REe_45oMeta || {},
    component: () => import("/app/pages/t/[slug].vue")
  },
  {
    name: "t-slug___de-de",
    path: "/de-de/t/:slug()/",
    meta: _91slug_93jk8NboFQK8o6mOnMfbHsC5QZC2vwrXPlCkqMu7REe_45oMeta || {},
    component: () => import("/app/pages/t/[slug].vue")
  },
  {
    name: "impressum___en-us",
    path: "/en-us/impressum/",
    meta: impressum3zzQy_454eQIfFpJ0LzE7oAVVZr5GATwT7mh6WVc9V9esMeta || {},
    component: () => import("/app/pages/impressum.vue")
  },
  {
    name: "impressum___de-de",
    path: "/de-de/impressum/",
    meta: impressum3zzQy_454eQIfFpJ0LzE7oAVVZr5GATwT7mh6WVc9V9esMeta || {},
    component: () => import("/app/pages/impressum.vue")
  },
  {
    name: "b2b-slug___en-us",
    path: "/en-us/b2b/:slug()/",
    meta: _91slug_93avbsmegKRJZMYUWMMAb6svjmxYB8KXoYxy9t5w_45JTscMeta || {},
    component: () => import("/app/pages/b2b/[slug].vue")
  },
  {
    name: "b2b-slug___de-de",
    path: "/de-de/b2b/:slug()/",
    meta: _91slug_93avbsmegKRJZMYUWMMAb6svjmxYB8KXoYxy9t5w_45JTscMeta || {},
    component: () => import("/app/pages/b2b/[slug].vue")
  },
  {
    name: "b2c-slug___en-us",
    path: "/en-us/b2c/:slug()/",
    meta: _91slug_93iBZZIx5uruMAMOi9JdheoEhEz2PW7bM1k_rNmmoolPwMeta || {},
    component: () => import("/app/pages/b2c/[slug].vue")
  },
  {
    name: "b2c-slug___de-de",
    path: "/de-de/b2c/:slug()/",
    meta: _91slug_93iBZZIx5uruMAMOi9JdheoEhEz2PW7bM1k_rNmmoolPwMeta || {},
    component: () => import("/app/pages/b2c/[slug].vue")
  },
  {
    name: "city___en-us",
    path: "/en-us/:city()/",
    component: () => import("/app/pages/[city]/index.vue")
  },
  {
    name: "city___de-de",
    path: "/de-de/:city()/",
    component: () => import("/app/pages/[city]/index.vue")
  },
  {
    name: "c-category___en-us",
    path: "/en-us/c/:category()/",
    meta: _91category_93bwwf1jAiRo8reV4FhnKcFuoHATkSID9O8dbQYiTdjL8Meta || {},
    component: () => import("/app/pages/c/[category].vue")
  },
  {
    name: "c-category___de-de",
    path: "/de-de/c/:category()/",
    meta: _91category_93bwwf1jAiRo8reV4FhnKcFuoHATkSID9O8dbQYiTdjL8Meta || {},
    component: () => import("/app/pages/c/[category].vue")
  },
  {
    name: "partner___en-us",
    path: "/en-us/partner/",
    meta: indexFvrGDTPKrce3FFQT7ij_hMGsmc6y5aKKjhJE2Sa_45GNEMeta || {},
    component: () => import("/app/pages/partner/index.vue")
  },
  {
    name: "partner___de-de",
    path: "/de-de/partner/",
    meta: indexFvrGDTPKrce3FFQT7ij_hMGsmc6y5aKKjhJE2Sa_45GNEMeta || {},
    component: () => import("/app/pages/partner/index.vue")
  },
  {
    name: "review-slug___en-us",
    path: "/en-us/review/:slug()/",
    meta: _91slug_93uGCKKC0ZMyweieY8ZQ4_45DM9XghGZx7NazNK8zFFgOekMeta || {},
    component: () => import("/app/pages/review/[slug].vue")
  },
  {
    name: "review-slug___de-de",
    path: "/de-de/review/:slug()/",
    meta: _91slug_93uGCKKC0ZMyweieY8ZQ4_45DM9XghGZx7NazNK8zFFgOekMeta || {},
    component: () => import("/app/pages/review/[slug].vue")
  },
  {
    name: "giftcard___en-us",
    path: "/en-us/giftcard/",
    meta: indexGsFsGsKI_utlx2Iox0NU96g0tiOjy2h96PZ2pzsYl9IMeta || {},
    component: () => import("/app/pages/giftcard/index.vue")
  },
  {
    name: "giftcard___de-de",
    path: "/de-de/giftcard/",
    meta: indexGsFsGsKI_utlx2Iox0NU96g0tiOjy2h96PZ2pzsYl9IMeta || {},
    component: () => import("/app/pages/giftcard/index.vue")
  },
  {
    name: "magazine___en-us",
    path: "/en-us/magazine/",
    meta: indexrsMovMnPAjYSiLcmtYlTJY_45ATOK7nA1wg0PnFCgJgFQMeta || {},
    component: () => import("/app/pages/magazine/index.vue")
  },
  {
    name: "magazine___de-de",
    path: "/de-de/magazine/",
    meta: indexrsMovMnPAjYSiLcmtYlTJY_45ATOK7nA1wg0PnFCgJgFQMeta || {},
    component: () => import("/app/pages/magazine/index.vue")
  },
  {
    name: "privacy-policy___en-us",
    path: "/en-us/privacy-policy/",
    component: () => import("/app/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___de-de",
    path: "/de-de/privacy-policy/",
    component: () => import("/app/pages/privacy-policy.vue")
  },
  {
    name: "terms-supplier___en-us",
    path: "/en-us/terms-supplier/",
    meta: terms_45suppliert904fGvJfOcyGqBCg6wWBppfFHw0LROvWS8pKAacWM0Meta || {},
    component: () => import("/app/pages/terms-supplier.vue")
  },
  {
    name: "terms-supplier___de-de",
    path: "/de-de/terms-supplier/",
    meta: terms_45suppliert904fGvJfOcyGqBCg6wWBppfFHw0LROvWS8pKAacWM0Meta || {},
    component: () => import("/app/pages/terms-supplier.vue")
  },
  {
    name: "teamevent___en-us",
    path: "/en-us/teamevent/",
    meta: indexyEZvi6gHI_45gMzPLm50ILF9eB9dY_45Vqn_drlaTaFEDQYMeta || {},
    component: () => import("/app/pages/teamevent/index.vue")
  },
  {
    name: "teamevent___de-de",
    path: "/de-de/teamevent/",
    meta: indexyEZvi6gHI_45gMzPLm50ILF9eB9dY_45Vqn_drlaTaFEDQYMeta || {},
    component: () => import("/app/pages/teamevent/index.vue")
  },
  {
    name: "k-boxPermalink___en-us",
    path: "/en-us/k/:boxPermalink()/",
    meta: _91boxPermalink_93lWf3QYZiCCLpdSuyZqumnhl2JfVwZQdNlEp_45_UM5H28Meta || {},
    component: () => import("/app/pages/k/[boxPermalink].vue")
  },
  {
    name: "k-boxPermalink___de-de",
    path: "/de-de/k/:boxPermalink()/",
    meta: _91boxPermalink_93lWf3QYZiCCLpdSuyZqumnhl2JfVwZQdNlEp_45_UM5H28Meta || {},
    component: () => import("/app/pages/k/[boxPermalink].vue")
  },
  {
    name: "sub-domain___en-us",
    path: "/en-us/sub-domain/",
    meta: indexXaeQOVwCnOyEhz9QjAfentyQuSjBcw4oqW3AChwd1mwMeta || {},
    component: () => import("/app/pages/sub-domain/index.vue")
  },
  {
    name: "sub-domain___de-de",
    path: "/de-de/sub-domain/",
    meta: indexXaeQOVwCnOyEhz9QjAfentyQuSjBcw4oqW3AChwd1mwMeta || {},
    component: () => import("/app/pages/sub-domain/index.vue")
  },
  {
    name: "teamevent-city___en-us",
    path: "/en-us/teamevent/:city()/",
    meta: _91city_93_0DWg42VypyTTIBW4aBVqJAXNYUXhY8pPZhp1rnQycEMeta || {},
    component: () => import("/app/pages/teamevent/[city].vue")
  },
  {
    name: "teamevent-city___de-de",
    path: "/de-de/teamevent/:city()/",
    meta: _91city_93_0DWg42VypyTTIBW4aBVqJAXNYUXhY8pPZhp1rnQycEMeta || {},
    component: () => import("/app/pages/teamevent/[city].vue")
  },
  {
    name: "city-category___en-us",
    path: "/en-us/:city()/:category()/",
    component: () => import("/app/pages/[city]/[category].vue")
  },
  {
    name: "city-category___de-de",
    path: "/de-de/:city()/:category()/",
    component: () => import("/app/pages/[city]/[category].vue")
  },
  {
    name: "b2b-employee-gifts___en-us",
    path: "/en-us/b2b/employee-gifts/",
    meta: employee_45giftsfzNpPPtj2UACvulouceKKOrA1LFHUcBPCxazjpO_BsMMeta || {},
    component: () => import("/app/pages/b2b/employee-gifts.vue")
  },
  {
    name: "b2b-employee-gifts___de-de",
    path: "/de-de/b2b/employee-gifts/",
    meta: employee_45giftsfzNpPPtj2UACvulouceKKOrA1LFHUcBPCxazjpO_BsMMeta || {},
    component: () => import("/app/pages/b2b/employee-gifts.vue")
  },
  {
    name: "e-eventPermalink___en-us",
    path: "/en-us/e/:eventPermalink()/",
    meta: _91eventPermalink_93m4cL0oikOFf6co1kVSXvMEEncUnVF1a7Ye7UC0OQnKMMeta || {},
    component: () => import("/app/pages/e/[eventPermalink].vue")
  },
  {
    name: "e-eventPermalink___de-de",
    path: "/de-de/e/:eventPermalink()/",
    meta: _91eventPermalink_93m4cL0oikOFf6co1kVSXvMEEncUnVF1a7Ye7UC0OQnKMMeta || {},
    component: () => import("/app/pages/e/[eventPermalink].vue")
  },
  {
    name: "giftcard-special___en-us",
    path: "/en-us/giftcard/:special()/",
    meta: _91special_93USc0GTc_45cup07TTwbBx7tkHqhP4zmSwia_45yfraj9JJAMeta || {},
    component: () => import("/app/pages/giftcard/[special].vue")
  },
  {
    name: "giftcard-special___de-de",
    path: "/de-de/giftcard/:special()/",
    meta: _91special_93USc0GTc_45cup07TTwbBx7tkHqhP4zmSwia_45yfraj9JJAMeta || {},
    component: () => import("/app/pages/giftcard/[special].vue")
  },
  {
    name: "sub-domain-giftcard___en-us",
    path: "/en-us/sub-domain/giftcard/",
    meta: giftcard5ovBVsS296fsBW4HMaEe8RJudPjxIEuddiqvQehBVV8Meta || {},
    component: () => import("/app/pages/sub-domain/giftcard.vue")
  },
  {
    name: "sub-domain-giftcard___de-de",
    path: "/de-de/sub-domain/giftcard/",
    meta: giftcard5ovBVsS296fsBW4HMaEe8RJudPjxIEuddiqvQehBVV8Meta || {},
    component: () => import("/app/pages/sub-domain/giftcard.vue")
  },
  {
    name: "widgets-all-widgets___en-us",
    path: "/en-us/widgets/all-widgets/",
    meta: all_45widgetsO68DqLGvHQqLopCQJCWaEKxin9H6zu52YYaFqAxotCgMeta || {},
    component: () => import("/app/pages/widgets/all-widgets.vue")
  },
  {
    name: "widgets-all-widgets___de-de",
    path: "/de-de/widgets/all-widgets/",
    meta: all_45widgetsO68DqLGvHQqLopCQJCWaEKxin9H6zu52YYaFqAxotCgMeta || {},
    component: () => import("/app/pages/widgets/all-widgets.vue")
  },
  {
    name: "p-partnerPermalink___en-us",
    path: "/en-us/p/:partnerPermalink()/",
    meta: _91partnerPermalink_93hUyjZCY_5tvnqURrYnzov7GBIkhcXvlrS1o34UFvEIcMeta || {},
    component: () => import("/app/pages/p/[partnerPermalink].vue")
  },
  {
    name: "p-partnerPermalink___de-de",
    path: "/de-de/p/:partnerPermalink()/",
    meta: _91partnerPermalink_93hUyjZCY_5tvnqURrYnzov7GBIkhcXvlrS1o34UFvEIcMeta || {},
    component: () => import("/app/pages/p/[partnerPermalink].vue")
  },
  {
    name: "terms-and-conditions___en-us",
    path: "/en-us/terms-and-conditions/",
    meta: terms_45and_45conditionsRU14D3TdeoevONy87DoGrbDHcLlYVbN3gK81tRUROX4Meta || {},
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___de-de",
    path: "/de-de/terms-and-conditions/",
    meta: terms_45and_45conditionsRU14D3TdeoevONy87DoGrbDHcLlYVbN3gK81tRUROX4Meta || {},
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "magazine-articleSlug___en-us",
    path: "/en-us/magazine/:articleSlug()/",
    meta: _91articleSlug_93BtQBnLypwY6_2xuuRu1iV1fcU3Dj3pEoaAoPXjOr_MAMeta || {},
    component: () => import("/app/pages/magazine/[articleSlug].vue")
  },
  {
    name: "magazine-articleSlug___de-de",
    path: "/de-de/magazine/:articleSlug()/",
    meta: _91articleSlug_93BtQBnLypwY6_2xuuRu1iV1fcU3Dj3pEoaAoPXjOr_MAMeta || {},
    component: () => import("/app/pages/magazine/[articleSlug].vue")
  },
  {
    name: "b2b-mitarbeitergeschenke___en-us",
    path: "/en-us/b2b/mitarbeitergeschenke/",
    meta: mitarbeitergeschenkenKvoTYDJSCuL4qZXkrEweZX8N9rKkfur6rhD9V0i7b0Meta || {},
    component: () => import("/app/pages/b2b/mitarbeitergeschenke.vue")
  },
  {
    name: "b2b-mitarbeitergeschenke___de-de",
    path: "/de-de/b2b/mitarbeitergeschenke/",
    meta: mitarbeitergeschenkenKvoTYDJSCuL4qZXkrEweZX8N9rKkfur6rhD9V0i7b0Meta || {},
    component: () => import("/app/pages/b2b/mitarbeitergeschenke.vue")
  },
  {
    name: "widgets-badge-partnerId___en-us",
    path: "/en-us/widgets/badge/:partnerId()/",
    meta: _91partnerId_939kNYrouLZUEvftk_45Fe6GijV3Dp_45vbLGeerVeD_45cCX9EMeta || {},
    component: () => import("/app/pages/widgets/badge/[partnerId].vue")
  },
  {
    name: "widgets-badge-partnerId___de-de",
    path: "/de-de/widgets/badge/:partnerId()/",
    meta: _91partnerId_939kNYrouLZUEvftk_45Fe6GijV3Dp_45vbLGeerVeD_45cCX9EMeta || {},
    component: () => import("/app/pages/widgets/badge/[partnerId].vue")
  },
  {
    name: "preview-previewPermalink___en-us",
    path: "/en-us/preview/:previewPermalink()/",
    meta: _91previewPermalink_93FmdUEzCh55z_hyPfKrd_45_45idpMxpTV_45zFgzal6nMtTgIMeta || {},
    component: () => import("/app/pages/preview/[previewPermalink].vue")
  },
  {
    name: "preview-previewPermalink___de-de",
    path: "/de-de/preview/:previewPermalink()/",
    meta: _91previewPermalink_93FmdUEzCh55z_hyPfKrd_45_45idpMxpTV_45zFgzal6nMtTgIMeta || {},
    component: () => import("/app/pages/preview/[previewPermalink].vue")
  },
  {
    name: "widgets-general-banner-partnerId___en-us",
    path: "/en-us/widgets/general-banner/:partnerId()/",
    meta: _91partnerId_93X_45NK6yaKWUWkvJEsrQj92nGbW9dm5n6lWB4koy30jKYMeta || {},
    component: () => import("/app/pages/widgets/general-banner/[partnerId].vue")
  },
  {
    name: "widgets-general-banner-partnerId___de-de",
    path: "/de-de/widgets/general-banner/:partnerId()/",
    meta: _91partnerId_93X_45NK6yaKWUWkvJEsrQj92nGbW9dm5n6lWB4koy30jKYMeta || {},
    component: () => import("/app/pages/widgets/general-banner/[partnerId].vue")
  },
  {
    name: "widgets-partner-banner-partnerId___en-us",
    path: "/en-us/widgets/partner-banner/:partnerId()/",
    meta: _91partnerId_93shN6i9v6ZU2r6M6EpFHzS5U_FBvZrW0lZfaHabKsPpIMeta || {},
    component: () => import("/app/pages/widgets/partner-banner/[partnerId].vue")
  },
  {
    name: "widgets-partner-banner-partnerId___de-de",
    path: "/de-de/widgets/partner-banner/:partnerId()/",
    meta: _91partnerId_93shN6i9v6ZU2r6M6EpFHzS5U_FBvZrW0lZfaHabKsPpIMeta || {},
    component: () => import("/app/pages/widgets/partner-banner/[partnerId].vue")
  },
  {
    name: "widgets-reviews-by-event-id-eventId___en-us",
    path: "/en-us/widgets/reviews-by-event-id/:eventId()/",
    meta: _91eventId_93_7tvjEQ2i7WAUbxKSkUx5zG3z_h44K5fpumJ1wwJ_4gMeta || {},
    component: () => import("/app/pages/widgets/reviews-by-event-id/[eventId].vue")
  },
  {
    name: "widgets-reviews-by-event-id-eventId___de-de",
    path: "/de-de/widgets/reviews-by-event-id/:eventId()/",
    meta: _91eventId_93_7tvjEQ2i7WAUbxKSkUx5zG3z_h44K5fpumJ1wwJ_4gMeta || {},
    component: () => import("/app/pages/widgets/reviews-by-event-id/[eventId].vue")
  },
  {
    name: "widgets-reviews-by-partner-id-partnerId___en-us",
    path: "/en-us/widgets/reviews-by-partner-id/:partnerId()/",
    meta: _91partnerId_93AK5WWBy6IAXNJ6rS6rU6cVNLO6jkeF1HVCrJHNKcbGEMeta || {},
    component: () => import("/app/pages/widgets/reviews-by-partner-id/[partnerId].vue")
  },
  {
    name: "widgets-reviews-by-partner-id-partnerId___de-de",
    path: "/de-de/widgets/reviews-by-partner-id/:partnerId()/",
    meta: _91partnerId_93AK5WWBy6IAXNJ6rS6rU6cVNLO6jkeF1HVCrJHNKcbGEMeta || {},
    component: () => import("/app/pages/widgets/reviews-by-partner-id/[partnerId].vue")
  }
]